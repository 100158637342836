<template>
  <div>
    <PageIntroduce :title="'风险态势'"
                   :desc="'通过差距分析，逐项比对法规的条款要求，快速识别到产品法律风险'" />
    <el-tabs v-model="activeName"
             type="border-card">
      <el-tab-pane label="风险统计"
                   :lazy="true"
                   name="1">
        <RiskView v-if="activeName == 1" />
      </el-tab-pane>
      <el-tab-pane label="风险态势图"
                   :lazy="true"
                   name="2">
        <RiskChart v-if="activeName == 2" />
      </el-tab-pane>
<!--      <el-tab-pane v-for="(item,index) in navMenus" :label="item.label"-->
<!--                   :lazy="true"-->
<!--                   :name="String(index+1)">-->
<!--        <RiskView v-if="item.label === '风险统计'" />-->
<!--        <RiskChart v-if="item.label === '风险态势图'"/>-->
<!--      </el-tab-pane>-->
    </el-tabs>
  </div>
</template>

<script>
import PageIntroduce from '@core/components/pageIntroduce/PageIntroduce.vue'
import RiskView from '@/views/risk/riskState/RiskView'
import RiskChart from '@/views/risk/riskState/RiskChart'

export default {
  components: {
    PageIntroduce,
    RiskView,
    RiskChart
  },
  created () {
    const twoLevelMenus = localStorage.getItem('twoLevelMenus')
    if(twoLevelMenus){
      const menus = JSON.parse(twoLevelMenus)
      menus.forEach(d=>{
        if(this.fullMenus.indexOf(d.name) !== -1){
          this.navMenus.push({
            label: d.name,
          })
        }
      })
    }
    this.activeName = sessionStorage.getItem('activeName') || '1'
  },
  data () {
    return {
      navMenus: [],
      fullMenus: ['风险统计','风险态势图'],
      activeName: '1',
    }
  },
  watch: {
    activeName () {
      sessionStorage.setItem('activeName', this.activeName)
    }
  },
  beforeRouteLeave (to, from, next) {
    sessionStorage.setItem('activeName', '1')
    next()
  }
}
</script>

<style>
</style>
