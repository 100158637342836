<template>
  <div>
    <!-- PIA数量统计  -->
    <b-card
      v-loading="totalLoading"
      no-body
      style="width: 100%;"
    >
      <b-card-header>
        <b-col class="font-medium-1 mt-50 center">
          <span class="title">风险数量统计</span>
        </b-col>
      </b-card-header>

      <b-card-body style="margin-left: 50px;">
        <b-row
          class="align-content-center h-100"
          style=""
        >
          <b-col
            v-for="(item, index) in barSeriesArr"
            :key="index"
          >
            <b-media no-body>
              <b-media-aside class="mr-1">
                <b-avatar
                  size="32"
                  :variant="backgrounds[index]"
                >
                  <feather-icon
                    size="16"
                    :icon="index == 0 ? `ArchiveIcon` : `CheckIcon` "
                  />
                </b-avatar>
              </b-media-aside>
              <b-media-body class="my-auto">
                <h5 class="font-weight-bolder mb-0">
                  <span class="info-title">{{ item }}</span>
                </h5>
                <b-card-text class="mb-0">
                  {{ categories[index] }}
                </b-card-text>
              </b-media-body>
            </b-media>
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>
    <b-card
      v-loading="lineLoading"
      no-body
      style="width: 100%;"
    >
      <b-card-header>
        <b-col class="font-medium-1 mt-10">
          <span class="title">风险趋势</span>
        </b-col>
        <div class="d-flex align-items-center">
          <el-date-picker
            v-model="rangePicker"
            type="daterange"
            format="yyyy-MM-dd"
            placement="bottom-start"
            value-format="yyyy-MM-dd"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            @change="dateChange"
          />
        </div>
      </b-card-header>

      <b-card-body>
        <div
          v-if="lineChartInit"
          sytle="height:240px;"
        >
          <vue-apex-charts
            v-if="lineSeries[0].data.length != 0"
            type="line"
            height="240"
            :options="lineOptions"
            :series="lineSeries"
          />
        </div>
        <el-empty
          v-if="lineSeries[0].data.length == 0"
          description="暂无数据"
          size="100"
        />
      </b-card-body>
    </b-card>
    <b-row>
      <b-col cols="4">
        <b-card
          v-loading="riskClassLoading"
          no-body
          style="height: 350px;"
        >
          <b-card-header style="padding-bottom: 0;">
            <b-col class="font-medium-1 mt-50 center">
              <span class="title">风险类别占比</span>
            </b-col>
          </b-card-header>

          <b-card-body>
            <vue-apex-charts
              v-if="riskClassSeries.length != 0"
              type="treemap"
              height="300"
              class="mt-0 mb-0"
              :options="riskClassOptions"
              :series="[{data: riskClassSeries}]"
            />
            <el-empty
              v-if="riskClassSeries.length == 0"
              description="暂无数据"
              size="100"
            />
          </b-card-body>
        </b-card>
      </b-col>
      <b-col cols="4">
        <b-card
          v-loading="riskWorkLoading"
          no-body
          style="height: 350px;"
        >
          <b-card-header>
            <b-col class="font-medium-1 mt-50 center">
              <span class="title">风险业务TOP5</span>
            </b-col>
          </b-card-header>

          <b-card-body>
            <div v-if="riskWorkInit">
              <vue-apex-charts
                v-if="riskWorkSeries[0].data.length != 0"
                type="bar"
                height="280"
                :options="riskWorkOptions"
                :series="riskWorkSeries"
              />
            </div>

            <el-empty
              v-if="riskWorkSeries[0].data.length == 0"
              description="暂无数据"
              size="100"
            />
          </b-card-body>
        </b-card>
      </b-col>
      <b-col cols="4">
        <b-card
          v-loading="riskLevelLoading"
          no-body
          style="height: 350px;"
        >
          <b-card-header>
            <b-col class="font-medium-1 mt-50 center">
              <span class="title">风险等级占比</span>
            </b-col>
          </b-card-header>

          <b-card-body>
            <div v-if="riskLevelInit">
              <vue-apex-charts
                v-if="riskLevelSeries.length != 0"
                type="donut"
                height="300"
                class="mt-0 mb-0"
                :options="riskLevelOptions"
                :series="riskLevelSeries"
              />
            </div>

            <el-empty
              v-if="riskLevelSeries.length == 0"
              description="暂无数据"
              size="100"
            />
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-card
          v-loading="riskSourceLoading"
          no-body
          style="height: 350px;"
        >
          <b-card-header style="padding-bottom: 0;">
            <b-col class="font-medium-1 mt-50 center">
              <span class="title">风险来源占比</span>
            </b-col>
          </b-card-header>

          <b-card-body>
            <vue-apex-charts
              v-if="riskSouceSeries.length != 0"
              type="treemap"
              height="300"
              class="mt-0 mb-0"
              :options="riskSouceOptions"
              :series="[{data: riskSouceSeries}]"
            />
            <el-empty
              v-if="riskSouceSeries.length == 0"
              description="暂无数据"
              size="100"
            />
          </b-card-body>
        </b-card>
      </b-col>
      <b-col>
        <b-card
          v-loading="riskRelatedLoading"
          no-body
          style="height: 350px;"
        >
          <b-card-header>
            <b-col class="font-medium-1 mt-50 center">
              <span class="title">风险关联对象TOP5</span>
            </b-col>
          </b-card-header>

          <b-card-body>
            <div v-if="riskRelatedInit">
              <vue-apex-charts
                v-if="riskRelatedSeries[0].data.length != 0"
                type="bar"
                height="280"
                :options="riskRelatedOptions"
                :series="riskRelatedSeries"
              />
            </div>

            <el-empty
              v-if="riskRelatedSeries[0].data.length == 0"
              description="暂无数据"
              size="100"
            />
          </b-card-body>
        </b-card>
      </b-col>
      <b-col>
        <b-card
          v-loading="riskStatusLoading"
          no-body
          style="height: 350px;"
        >
          <b-card-header>
            <b-col class="font-medium-1 mt-50 center">
              <span class="title">风险状态占比</span>
            </b-col>
          </b-card-header>

          <b-card-body>
            <vue-apex-charts
              v-if="riskStatusSeries.length != 0"
              type="donut"
              height="300"
              class="mt-0 mb-0"
              :options="riskStatusOptions"
              :series="riskStatusSeries"
            />
            <el-empty
              v-if="riskStatusSeries.length == 0"
              description="暂无数据"
              size="100"
            />
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import VueApexCharts from 'vue-apexcharts'
import Moment from 'moment'
import { $themeColors } from '@themeConfig'
import {
  GetRiskSatisfaction, GetRiskCountByMouth, GetRiskSatisfactionByTypes, GetRiskSatisfactionByRiskStatus,
  GetRiskSatisfactionByProduct, GetRiskSatisfactionByRiskLevel, GetRiskSatisfactionBySources, GetRiskSatisfactionByObject,
} from '@/api/risk/risk'

export default {
  components: {
    VueApexCharts,
  },

  data() {
    const colors = ['#7039c5', '#2b9bf4', '#FDAC34', '#ea5455', '#28c76f']
    const backgrounds = ['light-primary', 'light-warning', 'light-danger', 'light-success']
    const beginTime = Moment().subtract(6, 'months').format('YYYY-MM-DD')
    const endTime = Moment().format('YYYY-MM-DD')
    return {
      backgrounds,
      riskRelatedInit: false,
      riskWorkInit: false,
      riskLevelInit: false,
      lineChartInit: false,
      lineLoading: false,
      totalLoading: false,
      riskClassLoading: false,
      riskWorkLoading: false,
      riskLevelLoading: false,
      riskSourceLoading: false,
      riskRelatedLoading: false,
      riskStatusLoading: false,
      // barSeriesArr: [3, 3, 3, 3],
      barSeriesArr: [],
      categories: ['风险总数', '待处理风险数', '整改中风险数', '整改完成风险数'],
      rangePicker: [beginTime, endTime],
      listQuery: {
        beginTime,
        endTime,
      },
      totalLineLoading: false,
      lineSeries: [
        {
          name: '风险数量',
          // data: [14, 18, 15, 20, 12, 25]
          data: [],
        },
      ],
      lineOptions: {
        chart: {

          toolbar: { show: false },
          zoom: { enabled: false },
          type: 'line',
          dropShadow: {
            enabled: true,
            top: 18,
            left: 2,
            blur: 5,
            opacity: 0.2,
          },
          offsetX: -10,
        },
        stroke: {
          curve: 'smooth',
          width: 4,
        },
        dataLabels: {
          enabled: true,
          offsetY: -10,
          background: {
            enabled: true,
            padding: 4,
            borderRadius: 2,
            borderColor: '#fff',
            opacity: 0.5,
            dropShadow: {
              enabled: true,
              top: 1,
              left: 1,
              blur: 1,
              color: '#000',
              opacity: 0.45,
            },
          },
        },
        grid: {
          borderColor: '#ebe9f1',
          padding: {
            top: -20,
            bottom: 5,
            left: 20,
          },
        },
        legend: {
          show: false,
        },
        colors: ['#df87f2'],
        fill: {
          type: 'gradient',
          gradient: {
            shade: 'dark',
            inverseColors: false,
            gradientToColors: [$themeColors.primary],
            shadeIntensity: 1,
            type: 'horizontal',
            opacityFrom: 1,
            opacityTo: 1,
            stops: [0, 100, 100, 100],
          },
        },
        markers: {
          size: 0,
          hover: {
            size: 5,
          },
        },
        xaxis: {
          labels: {
            offsetY: 5,
            style: {
              colors: '#b9b9c3',
              fontSize: '0.857rem',
            },
          },
          axisTicks: {
            show: false,
          },
          // categories: ['2022/10', '2022/11', '2022/12', '2023/01', '2023/02', '2023/03',],
          categories: [],
          axisBorder: {
            show: false,
          },
          tickPlacement: 'on',
        },
        yaxis: {
          tickAmount: 5,
          labels: {
            style: {
              colors: '#b9b9c3',
              fontSize: '0.857rem',
            },
          },
        },
        tooltip: {
          y: {
            formatter(val) {
              return val
            },
          },
        },
      },
      // riskClassSeries: [12, 34, 11, 2],
      riskClassSeries: [],
      riskClassOptions: {
        chart: {
          type: 'treemap',
          toolbar: {
            show: false,
          },
        },
        plotOptions: {
          treemap: {
            distributed: true,
            enableShades: false,
          },
        },
      },
      riskWorkSeries: [{
        name: '',
        // data: [17, 14, 8, 6, 21],
        data: [],
      }],
      riskWorkOptions: {
        chart: {
          toolbar: {
            show: false,
          },
        },
        colors: '#03C6FE',
        plotOptions: {
          bar: {
            horizontal: false,
            barHeight: '30%',
            columnWidth: '30%',
            endingShape: 'rounded',
          },
        },
        grid: {
          xaxis: {
            lines: {
              show: false,
            },
          },
        },
        dataLabels: {
          enabled: true,
        },
        xaxis: {
          // categories: ['业务系统', '采购系统', '整体组织', '第三方组织', '仓管系统',],
          categories: [],
        },
        yaxis: {
        },
        tooltip: {
          y: {
            formatter(val) {
              return val
            },
          },
        },
      },
      // riskLevelSeries: [0, 6, 14, 21, 14],
      riskLevelSeries: [],
      riskLevelOptions: {
        chart: {
          toolbar: {
            show: false,
          },
        },
        legend: {
          show: true,
          position: 'bottom',
        },
        labels: [],
        dataLabels: {
          enabled: true,
          formatter(value, { seriesIndex, w }) {
            return w.config.series[seriesIndex]
          },
        },
        fill: {
          type: 'gradient',
        },
        stroke: {
          width: 1,
        },
        plotOptions: {
          pie: {
            donut: {
              labels: {
                show: true,
                value: {
                  fontSize: '2rem',
                  fontFamily: 'Montserrat',
                  formatter(val) {
                    return `${parseInt(val)}`
                  },
                },
                total: {
                  show: true,
                  fontSize: '2rem',
                  label: '总计',
                  color: '#03C6FE',
                },
              },
            },
          },
        },
        colors: ['#28c711', '#ffe0c2', '#ff9f43', '#ea5455'],
      },
      // riskSouceSeries: [20, 24, 11],
      riskSouceSeries: [],
      riskSouceOptions: {
        chart: {
          toolbar: {
            show: false,
          },
        },
        dataLabels: {
          enabled: true,
        },
        plotOptions: {
          treemap: {
            distributed: true,
            enableShades: false,
          },
        },
      },
      riskRelatedSeries: [{
        name: '',
        // data: [17, 14, 8, 6, 21],
        data: [],
      }],
      riskRelatedOptions: {
        chart: {
          toolbar: {
            show: false,
          },
        },
        colors: '#03C6FE',
        plotOptions: {
          bar: {
            horizontal: false,
            barHeight: '30%',
            columnWidth: '30%',
            endingShape: 'rounded',
          },
        },
        grid: {
          xaxis: {
            lines: {
              show: false,
            },
          },
        },
        dataLabels: {
          enabled: true,
        },
        xaxis: {
          // categories: ['业务系统', '采购系统', '整体组织', '第三方组织', '仓管系统',],
          categories: [],
        },
        yaxis: {
        },
        tooltip: {
          y: {
            formatter(val) {
              return val
            },
          },
        },
      },
      // riskStatusSeries: [21, 33, 12, 11],
      riskStatusSeries: [],
      riskStatusOptions: {
        chart: {
          toolbar: {
            show: false,
          },
        },
        legend: {
          show: true,
          position: 'bottom',
        },
        // labels: ['待处理', '整改中', '已处理', '已关闭'],
        labels: [],
        dataLabels: {
          enabled: true,
          formatter(value, { seriesIndex, w }) {
            return w.config.series[seriesIndex]
          },
        },
        fill: {
          type: 'gradient',
        },
        stroke: {
          width: 1,
        },
        plotOptions: {
          pie: {
            donut: {
              labels: {
                show: true,
                value: {
                  fontSize: '2rem',
                  fontFamily: 'Montserrat',
                  formatter(val) {
                    return `${parseInt(val)}`
                  },
                },
                total: {
                  show: true,
                  fontSize: '2rem',
                  label: '总计',
                },
              },
            },
          },
        },
      },
    }
  },
  created() {
    this.getRiskSatisfaction()
    this.getRiskCountByMouth()
    this.getRiskSatisfactionByTypes()
    this.getRiskSatisfactionByProduct()
    this.getRiskSatisfactionByRiskLevel()
    this.getRiskSatisfactionBySources()
    this.getRiskSatisfactionByObject()
    this.getRiskSatisfactionByRiskStatus()
  },
  methods: {
    dateChange() {
      if (this.rangePicker) {
        this.listQuery.beginTime = this.rangePicker[0]
        this.listQuery.endTime = this.rangePicker[1]
      } else {
        this.listQuery.beginTime = ''
        this.listQuery.endTime = ''
      }
      this.getRiskCountByMouth()
    },
    getRiskSatisfaction() {
      this.totalLoading = true
      GetRiskSatisfaction().then(res => {
        this.totalLoading = false
        const resData = res.data
        if (resData.code === 0) {
          this.barSeriesArr.push(resData.data.riskTotal)
          this.barSeriesArr.push(resData.data.waitRectificationCount)
          this.barSeriesArr.push(resData.data.InRectificationCount)
          this.barSeriesArr.push(resData.data.finishRectificationCount)
        }
      })
    },
    getRiskCountByMouth() {
      this.lineLoading = true
      this.lineChartInit = false
      GetRiskCountByMouth(this.listQuery).then(res => {
        this.lineLoading = false
        this.lineOptions.xaxis.categories = []
        this.lineSeries[0].data = []
        const resData = res.data
        if (resData.code === 0) {
          const lineData = resData.data
          lineData.forEach(line => {
            this.lineOptions.xaxis.categories.push(line.mouth)
            this.lineSeries[0].data.push(line.riskCount)
          })
          this.lineChartInit = true
        }
      })
    },
    getRiskSatisfactionByTypes() {
      this.riskClassLoading = true
      GetRiskSatisfactionByTypes().then(res => {
        this.riskClassLoading = false
        const resData = res.data
        if (resData.code === 0) {
          const chartData = resData.data.ratioList
          chartData.forEach(chart => {
            this.riskClassSeries.push({
              x: chart.name,
              y: chart.count,
            })
          })
        }
      })
    },
    getRiskSatisfactionByProduct() {
      this.riskWorkLoading = true
      this.riskWorkInit = false
      GetRiskSatisfactionByProduct().then(res => {
        this.riskWorkLoading = false
        const resData = res.data
        if (resData.code === 0) {
          const chartData = resData.data
          chartData.forEach(chart => {
            this.riskWorkSeries[0].data.push(chart.value)
            this.riskWorkOptions.xaxis.categories.push(chart.name)
          })
          this.riskWorkInit = true
        }
      })
    },
    getRiskSatisfactionByRiskLevel() {
      this.riskLevelLoading = true
      this.riskLevelInit = false
      GetRiskSatisfactionByRiskLevel().then(res => {
        this.riskLevelLoading = false
        const resData = res.data
        if (resData.code === 0) {
          const chartData = resData.data.ratioList
          chartData.forEach(chart => {
            this.riskLevelSeries.push(chart.count)
            this.riskLevelOptions.labels.push(chart.name)
          })
          this.riskLevelInit = true
        }
      })
    },
    getRiskSatisfactionBySources() {
      this.riskSourceLoading = true
      GetRiskSatisfactionBySources().then(res => {
        this.riskSourceLoading = false
        const resData = res.data
        if (resData.code === 0) {
          const chartData = resData.data.ratioList
          chartData.forEach(chart => {
            this.riskSouceSeries.push({
              x: chart.name,
              y: chart.count,
            })
          })
        }
      })
    },
    getRiskSatisfactionByObject() {
      this.riskRelatedLoading = true
      this.riskRelatedInit = false
      GetRiskSatisfactionByObject().then(res => {
        this.riskRelatedLoading = false
        const resData = res.data
        if (resData.code === 0) {
          const chartData = resData.data
          chartData.forEach(chart => {
            this.riskRelatedSeries[0].data.push(chart.value)
            this.riskRelatedOptions.xaxis.categories.push(chart.name)
          })
          this.riskRelatedInit = true
        }
      })
    },
    getRiskSatisfactionByRiskStatus() {
      this.riskStatusLoading = true
      GetRiskSatisfactionByRiskStatus().then(res => {
        this.riskStatusLoading = false
        const resData = res.data
        if (resData.code === 0) {
          const chartData = resData.data.ratioList
          chartData.forEach(chart => {
            this.riskStatusSeries.push(chart.count)
            this.riskStatusOptions.labels.push(chart.name)
          })
        }
      })
    },
  },
}
</script>

<style lang="scss">
/*title*/
.title {
  border-left: solid 6px #0d40b2;
  padding-left: 8px;
  margin-bottom: 2px;
}
</style>
