<template>
  <div v-loading="loading">
    <div
      id="chart"
      :style="{width: width + 'px', height: height + 'px'}"
    />
    <el-dialog
      title="风险列表"
      :visible.sync="dialogVisible"
      width="960px"
    >
      <div class="table-wrap">
        <el-table
          border
          fit
          :data="riskList"
          stripe
          style="width: 100%"
        >
          <el-table-column
            prop="riskType"
            :show-overflow-tooltip="true"
            label="风险类别"
          />
          <el-table-column
            prop="explanation"
            :show-overflow-tooltip="true"
            label="风险描述"
          />
          <el-table-column
            prop="riskObjectName"
            label="关联对象"
          />
          <el-table-column
            prop="riskLevel"
            width="80"
            label="风险等级"
          >
            <template slot-scope="{row}">
              <b-badge
                class="d-flex justify-content-center"
                style="align-items: center"
                pill
                :variant="levelColorObj[row.riskLevel]"
              >
                {{ levelObj[row.riskLevel] }}
              </b-badge>
            </template>
          </el-table-column>
          <el-table-column
            prop="riskSourceName"
            :show-overflow-tooltip="true"
            width="70"
            label="风险来源"
          />
          <el-table-column
            width="100"
            prop="createTime"
            label="风险产生时间"
          >
            <template slot-scope="scope">
              {{ formatDateStr(scope.row.createTime) }}
            </template>
          </el-table-column>
          <el-table-column
            width="80"
            prop="riskStatus"
            label="风险状态"
          >
            <template slot-scope="{row}">
              {{ riskStatusObj[row.riskStatus] }}
            </template>
          </el-table-column>
          <el-table-column
            prop="disposalAdvice"
            :show-overflow-tooltip="true"
            label="处置建议"
          />
          <el-table-column
            prop="assignUser"
            :show-overflow-tooltip="true"
            label="发布人员"
          />
          <el-table-column
            prop="handleUser"
            :show-overflow-tooltip="true"
            label="处理人员"
          />
          <el-table-column
            prop="finishTime"
            width="90"
            label="完成时间"
          >
            <template slot-scope="{row}">
              {{ formatDateStr(row.finishTime) }}
            </template>
          </el-table-column>
        </el-table>
        <pagination
          v-show="risks.length>5"
          :total="risks.length"
          :page.sync="page"
          :limit="10"
          @pagination="changeList"
        />
      </div>
    </el-dialog>
  </div>
</template>

<script>
import G6 from '@antv/g6'
import pagination from '@core/components/pagination/Pagination'
import { GetPrivateFlowChart } from '@/api/risk/risk'

export default {
  components: {
    pagination,
  },
  data() {
    return {
      page: 1,
      loading: true,
      dialogVisible: false,
      width: document.body.clientWidth - 320,
      height: document.body.clientHeight - 240,
      chartData: [],
      linkData: [],
      nodeData: {
        // 节点
        nodes: [],
        // 边
        edges: [],
      },
      listQuery: {
        assetsId: 0,
        fieldId: 0,
        productId: 0,
        thirdPartyId: 0,
      },
      riskList: [],
      risks: [],
      levelColorObj: {
        5: 'danger',
        4: 'warning',
        3: 'light-warning',
        2: 'success',
        1: 'success',
      },
      levelObj: {
        1: '极低',
        2: '低',
        3: '中',
        4: '高',
        5: '严重',
      },
      riskStatusObj: {
        1: '待处理',
        2: '驳回 ',
        3: '待整改',
        4: '待审核',
        5: '未通过审核',
        6: '处理完成',
        7: '关闭',
        8: '自动关闭',
      },
      colors: [
        '#FFC14B', '#92FFFF', '#ACFF96', '#7583FF', '#73C3FF',
        '#5477FE', '#5F57F9', '#7982FE', '#4F78FE', '#2B9DFF',
        '#61FEFF', '#937FE6', '#2B56D3', '#87E7AA', '#937FE6',
        '#FF9B97', '#8f23f5', '#0576ea', '#2cb8cf', '#8A7EE0',
        '#2cb8cf', '#4e70f0', '#1fa3de', '#bbc951', '#2D9BFF',
        '#b785a6', '#04E0F3', '#682EFC', '#35A7FE', '#0DC09F',
        '#682EFC', '#ED6663', '#8f23f5', '#0576ea', '#2cb8cf',
        '#8A7EE0', '#2cb8cf', '#4e70f0', '#1fa3de', '#bbc951',
        '#b785a6', '#39BFFF', '#76C2FF', '#5F57FC', '#FFC14B',
      ],
    }
  },
  mounted() {
    this.getEnterpriseAssetsPrivacyFieldFlowList()
  },
  methods: {
    changeList({ page }) {
      this.riskList = this.risks.slice((page - 1) * 10, page * 10 > this.risks.length ? this.risks.length : page * 10)
    },
    getEnterpriseAssetsPrivacyFieldFlowList() {
      GetPrivateFlowChart(this.listQuery).then(res => {
        this.loading = false
        const resData = res.data
        const fittingString = (str, maxWidth) => {
          let currentWidth = 0
          let res = str
          const pattern = new RegExp('[\u4E00-\u9FA5]+') // distinguish the Chinese charactors and letters
          str.split('').forEach((letter, i) => {
            if (currentWidth > maxWidth) return
            if (pattern.test(letter)) {
              // Chinese charactors
              currentWidth += 12
            } else {
              // get the width of single letter according to the fontSize
              currentWidth += G6.Util.getLetterWidth(letter, 12)
            }
            if (currentWidth > maxWidth) {
              res = `${str.substr(0, i)}\n${str.substr(i)}`
            }
          })
          return res
        }
        const shortenString = (str, maxWidth) => {
          if (str.length > maxWidth) {
            return `${str.substr(0, maxWidth)}...`
          }
          return str
        }
        const newData = {
          nodes: [],
          edges: resData.data.lineList,
          combos: [
            {
              id: 'combo1',
              label: '业务系统',
            },
            {
              id: 'combo2',
              label: '资产',
            },
            {
              id: 'combo3',
              label: '第三方系统',
            },
          ],
        }
        const nodes1 = resData.data.nodeList.filter(n => n.type === 1)
        const nodes2 = resData.data.nodeList.filter(n => n.type === 2).sort((a, b) => a.depth - b.depth)
        const nodes3 = resData.data.nodeList.filter(n => n.type === 3)
        const nodes4 = resData.data.nodeList.filter(n => n.type === 4)
        let maxLength = Math.max(nodes1.length, nodes3.length, nodes4.length)
        for (const [index, node] of nodes1.entries()) {
          newData.nodes.push({
            id: node.name,
            label: node.name,
            value: node.value,
            x: 70,
            y: 80 + (index * 80),
            img: require('@/assets/images/icons/map/app.png'),
            comboId: `combo${node.type}`,
            parentId: `combo${node.type}`,
            type: node.value.hasRisk ? 'extra-shape-node' : 'image',
          })
        }
        const lengths = [
          nodes2.filter(n => n.depth === 1).length,
          nodes2.filter(n => n.depth === 2).length,
          nodes2.filter(n => n.depth === 3).length,
          nodes2.filter(n => n.depth === 4).length,
          nodes2.filter(n => n.depth === 5).length,
        ]
        if (nodes1.length <= 0) {
          newData.combos[0].x = 70
          newData.combos[0].y = 70
        }
        if (nodes2.length <= 0) {
          newData.combos[1].x = this.width / 2
          newData.combos[1].y = 70
        } else {
          for (const i in lengths) {
            // if (lengths[i]) {
            //   newData.combos.push({
            //     id: 'combo' + (Number(i) + 4),
            //     parentId: 'combo2',
            //   })
            // }
          }
        }
        if (nodes3.length <= 0) {
          newData.combos[2].x = this.width - 70
          newData.combos[2].y = 70
        }
        let depth = 0; let
          count = 0
        for (const [index, node] of nodes2.entries()) {
          if (node.depth != depth) {
            count > maxLength && (maxLength = count)
            count = 0
          }
          const config = {
            id: node.name,
            label: node.name,
            value: node.value,
            // x: 400,
            x: this.width / 2,
            // y: 100 + (count * 80),
            y: 80 + (index * 80),
            // comboId: 'combo' + (node.depth + 3),
            comboId: `combo${node.type}`,
            parentId: `combo${node.type}`,
            type: node.value.hasRisk ? 'extra-shape-node' : 'image',
          }
          switch (node.depth) {
            case 1:
              // config.x = this.width / 5 + 20
              config.img = require('@/assets/images/icons/map/web.png')
              break
            case 2:
              // config.x = this.width / 5 * 1.8
              config.img = require('@/assets/images/icons/map/serve.png')
              break
            case 3:
              // config.x = this.width / 5 * 2.5
              config.img = require('@/assets/images/icons/map/database.png')
              break
            case 4:
              // config.x = this.width / 5 * 3.2
              config.img = require('@/assets/images/icons/map/secure.png')
              break
            case 5:
              // config.x = this.width / 5 * 4 - 20
              config.img = require('@/assets/images/icons/map/comm.png')
            default:
              break
          }
          depth = node.depth
          count++
          newData.nodes.push(config)
        }
        for (const [index, node] of nodes3.entries()) {
          newData.nodes.push({
            id: node.name,
            label: node.name,
            value: node.value,
            x: this.width - 70,
            y: 80 + (index * 80),
            img: require('@/assets/images/icons/map/third.png'),
            comboId: `combo${node.type}`,
            comboId: `combo${node.type}`,
            type: node.value.hasRisk ? 'extra-shape-node' : 'image',
          })
        }
        this.height = Math.max(this.height, maxLength * 80 + 150)
        for (const [index, node] of nodes4.entries()) {
          const x = Math.max(200, this.width / 5)
          const config = {
            id: node.name,
            label: node.name,
            value: node.value,
            size: [x, 40],
            x: this.width / 5,
            // y: this.height - 35,
            y: this.height + 200,
            anchorPoints: [
              [0.5, 0],
              [0, 0.5],
              [1, 0.5],
            ],
            img: require('@/assets/images/icons/map/organization.png'),
            type: node.value.hasRisk ? 'extra-shape-node' : 'image',
          }
          if (node.name == '管理制度') {
            // config.x += x + 100
            config.x = this.width / 5 + 100,
            config.img = require('@/assets/images/icons/map/rules.png')
          }
          newData.nodes.push(config)
        }
        newData.edges.forEach(d => {
          const color = this.colors[Math.floor(Math.random() * 45)]
          d.style = {
            stroke: color,
            fill: color,
          }
          if (d.value.hasRisk) {
            d.type = 'extra-shape-edge'
          }
        })
        G6.registerEdge(
          'extra-shape-edge',
          {
            afterDraw(cfg, group) {
              // get the first shape in the graphics group of this edge, it is the path of the edge here
              // 获取图形组中的第一个图形，在这里就是边的路径图形
              const shape = group.get('children')[0]
              // get the coordinate of the mid point on the path
              // 获取路径图形的中点坐标
              const midPoint = shape.getPoint(0.5)
              // add a rect on the mid point of the path. note that the origin of a rect shape is on its lefttop
              // 在中点增加一个矩形，注意矩形的原点在其左上角
              if (midPoint) {
                group.addShape('circle', {
                  attrs: {
                    r: 5,
                    fill: 'red',
                    x: midPoint.x,
                    y: midPoint.y,
                  },
                })
              }
            },
          },
          'line',
        )
        G6.registerNode(
          'extra-shape-node',
          {
            afterDraw(cfg, group) {
              group.addShape('circle', {
                attrs: {
                  r: 5,
                  fill: 'red',
                  x: 0,
                  y: -(cfg.size[1] / 2 + 5),
                },
                name: cfg.id,
              })
            },
          },
          'image',
        )
        const tooltip = new G6.Tooltip({
          offsetX: 10,
          offsetY: 20,
          getContent(e) {
            const outDiv = document.createElement('div')
            outDiv.innerHTML = `<h4 style="margin:0;">${e.item.getModel().id}</h4>`
            return outDiv
          },
          itemTypes: ['node'],
        })
        const graph = new G6.Graph({
          container: 'chart',
          width: this.width,
          height: this.height,
          fitView: true,
          renderer: 'svg', // 自定义dom节点需配置
          plugins: [tooltip],
          animate: true,
          minZoom: 0.1,
          maxZoom: 1,
          // 'drag-node'
          modes: { default: ['drag-canvas', 'zoom-canvas'] },
          groupByTypes: false,
          defaultNode: {
            size: 50,
            type: 'image',
            labelCfg: {
              position: 'bottom',
              offset: -1,
              style: {
                fontSize: 12,
              },
            },
            anchorPoints: [
              [0, 0.5],
              [1, 0.5],
            ],
          },
          defaultEdge: {
            type: 'line',
            size: 2,
            color: '#0d40b2',
            labelCfg: {
              autoRotate: false,
              refX: 0,
              refY: 10,
            },
            style: {
              startArrow: {
                path: G6.Arrow.circle(-3, 0),
              },
              endArrow: {
                path: G6.Arrow.triangle(5, -10, 0),
              },
              lineWidth: 1,
              fontSize: 12,
              fill: '#0d40b2',
              stroke: '#0d40b2',
            },
          },
          defaultCombo: {
            type: 'rect',
            labelCfg: {
              position: 'top-center',
              refY: -30,
              style: {
                fill: '#0d40b2',
                fontSize: 18,
              },
            },
          },
        })
        graph.data(newData)
        graph.render()
        graph.on('node:mouseenter', evt => {
          const item = evt.item._cfg.edges
          const edges = graph.getEdges()
          edges.forEach(edge => {
            if (edge === item.find(e => e._cfg.id === edge._cfg.id)) {
              return
            }
            graph.hideItem(edge)
          })
        })
        graph.on('node:mouseleave', evt => {
          const item = evt.item._cfg.edges
          const edges = graph.getEdges()
          edges.forEach(edge => {
            if (edge === item.find(e => e._cfg.id === edge._cfg.id)) {
              return
            }
            graph.showItem(edge)
          })
        })
        graph.on('node:click', evt => {
          const risk = evt.item._cfg.model.value
          if (risk.hasRisk) {
            this.risks = risk.riskList
            this.riskList = this.risks.length > 5 ? this.risks.slice(0, 10) : this.risks
            this.dialogVisible = true
          }
        })
        graph.on('edge:mouseenter', evt => {
          const { item } = evt
          const edges = graph.getEdges()
          edges.forEach(edge => {
            if (edge === item) {
              return
            }
            graph.hideItem(edge)
          })
        })
        graph.on('edge:mouseleave', evt => {
          const { item } = evt
          const edges = graph.getEdges()
          edges.forEach(edge => {
            if (edge === item) {
              return
            }
            graph.showItem(edge)
          })
        })
        graph.on('edge:click', evt => {
          const risk = evt.item._cfg.model.value
          if (risk.hasRisk) {
            this.risks = risk.riskList
            this.riskList = this.risks.length > 5 ? this.risks.slice(0, 10) : this.risks
            this.dialogVisible = true
          }
        })
      })
    },
  },
}
</script>

<style >
.field-toolip {
  max-height: 200px;
  padding: 10px;
  overflow-y: scroll;
}
.field-item {
  font-size: 12px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #606266;
}
.g6-component-tooltip:empty {
  display: none !important;
}
</style>
